var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-component", {
        attrs: {
          "items-repo": _vm.profilesRepo,
          "load-params": {
            PracticeId: _vm.$route.params.id,
            ProviderId: +_vm.$route.params.itemData,
          },
          isDataFromModel: false,
          sidebarData: _vm.selectedProfile,
          fields: _vm.fields,
          isAddEditSidbar: false,
          copyData: _vm.selectedProfileCopy,
          "add-edit-link": {
            name: "practices-details-view-profile",
            params: {
              itemData: _vm.selectedProfile.id ? _vm.selectedProfile.id : "new",
            },
          },
          "get-by-id-on-edit-filter": function () {
            return false
          },
        },
        on: {
          "update:sidebarData": function ($event) {
            _vm.selectedProfile = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.selectedProfile = $event
          },
          "update:copyData": function ($event) {
            _vm.selectedProfileCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.selectedProfileCopy = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(profileName)",
            fn: function (data) {
              return [
                _c("b", [_vm._v(_vm._s(data.item.name))]),
                _c("br"),
                _c("span", { staticClass: "grey-text smaller-text" }, [
                  _vm._v("ID: " + _vm._s(data.item.id) + " "),
                  _c("b", [_vm._v(" | ")]),
                  _vm._v(" SC:\n              " + _vm._s(data.item.code)),
                ]),
              ]
            },
          },
          {
            key: "cell(provider)",
            fn: function (data) {
              return [
                _c("b", [
                  _vm._v(
                    _vm._s(data.item.provider ? data.item.provider.name : "")
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(corporation)",
            fn: function (data) {
              return [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      data.item.corporation ? data.item.corporation.name : ""
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(location)",
            fn: function (data) {
              return [
                _c("b", [
                  _vm._v(
                    _vm._s(data.item.location ? data.item.location.name : "")
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(insCompany)",
            fn: function (data) {
              return [
                _c("b", [
                  _vm._v(
                    _vm._s(data.item.insurance ? data.item.insurance.name : "")
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(ipa)",
            fn: function (data) {
              return [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      data.item.insurance
                        ? data.item.insurance.isIpa
                          ? "Yes"
                          : "No"
                        : ""
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(action-left)",
            fn: function (data) {
              return [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(40, 199, 111, 0.15)",
                        expression: "'rgba(40, 199, 111, 0.15)'",
                        modifiers: { 400: true },
                      },
                    ],
                    attrs: { variant: "flat-primary", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.onRowSelected(data.item, "clone")
                      },
                    },
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "CopyIcon", size: "16" },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-form",
            [
              _c(
                "b-sidebar",
                {
                  attrs: {
                    id: "clonebar-right",
                    "bg-variant": "white",
                    "no-header": "",
                    right: "",
                    backdrop: "",
                    shadow: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "b-container",
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c(
                                      "div",
                                      { staticClass: "w-100 text-right pb-2" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            staticClass: "ml-1 mr-1",
                                            attrs: {
                                              variant: "outline-primary",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.clonebar = false
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Cancel\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "primary",
                                              size: "sm",
                                              type: "submit",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.validationForm.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "mr-50",
                                              attrs: { icon: "CheckIcon" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "align-middle" },
                                              [_vm._v("Clone")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.clonebar,
                    callback: function ($$v) {
                      _vm.clonebar = $$v
                    },
                    expression: "clonebar",
                  },
                },
                [
                  _c(
                    "b-container",
                    { staticClass: "px-2 mt-1 pb-3" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("h4", [_vm._v("Clone Profile")]),
                              _c("p", [
                                _vm._v(
                                  "\n                Select the settings you want to be duplicated to the new\n                profile\n              "
                                ),
                              ]),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Clone From" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Clone From",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-select", {
                                              attrs: {
                                                options: _vm.formData,
                                                "text-field": "name",
                                                "value-field": "id",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.provider,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "provider",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedRow.provider",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "New Profile Name" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Profile Name",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: "",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "selectedRow.name",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                { staticClass: "mt-3" },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Cloned Item",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    on: {
                                                      change: _vm.cloneAll,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Clone All\n                    "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.clonedItems
                                                            .length > 0,
                                                        expression:
                                                          "clonedItems.length > 0",
                                                      },
                                                      {
                                                        name: "ripple",
                                                        rawName: "v-ripple.400",
                                                        value:
                                                          "rgba(255, 255, 255, 0.15)",
                                                        expression:
                                                          "'rgba(255, 255, 255, 0.15)'",
                                                        modifiers: {
                                                          400: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      variant: "flat-danger",
                                                      size: "md",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.clonedItems = []
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Unselect All\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("hr"),
                                            _c("b-form-checkbox-group", {
                                              attrs: {
                                                options: _vm.cloneOptions,
                                                "text-field": "description",
                                                "value-field": "fieldName",
                                                stacked: true,
                                              },
                                              model: {
                                                value: _vm.clonedItems,
                                                callback: function ($$v) {
                                                  _vm.clonedItems = $$v
                                                },
                                                expression: "clonedItems",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }