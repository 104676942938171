/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AuthorizationSettingsDto,
    BooleanModelResponse,
    ColumnFilter,
    CorporationFeeSchedulesDto,
    CorporationsDto,
    DailyLimitLineType,
    EmailDto,
    FeeScheduleOverrideDto,
    Gender,
    GeneralStatus,
    GlobalPeriodType,
    InsuranceContactDto,
    InsurancePlansDto,
    InsurancesDto,
    LocationContectsDto,
    LocationsDto,
    LocationType,
    PatientBillingType,
    PhoneDto,
    PhoneType,
    PracticeContactsDto,
    PracticeSettingsDto,
    PracticeUsersDto,
    ProcedureCodesDto,
    ProcedureSettingsDiagnosisDto,
    ProcedureSettingsDto,
    ProcedureSettingsModifierDto,
    ProcedureSettingsPlaceOfServiceDto,
    ProcedureTypeSettingModifiersDto, ProfileContractDto,
    ProfilesDto,
    ProfilesDtoListResponseModel,
    ProfilesDtoModelResponse,
    ProvidersDto,
    ProviderSpecialtysDto,
    SortType,
    WebLinksDto,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Profiles<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Profiles
   * @name CombinationExistsList
   * @request GET:/Profiles/CombinationExists
   */
  combinationExistsList = (
    query: {
      PracticeId?: number;
      ProviderId?: number;
      CorporationId?: number;
      LocationId?: number;
      InsuaranceId?: number;
      Name?: string;
      Code?: string;
      IsPrimary?: boolean;   
      ProfileContracts?: ProfileContractDto[];
      Id?: number;
      Status?: GeneralStatus;
    },
    params: RequestParams = {},
  ) =>
    this.request<BooleanModelResponse, any>({
      path: `/Profiles/CombinationExists`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profiles
   * @name LoadList
   * @request GET:/Profiles/Load
   */
  loadList = (
    query?: {
      ProviderId?: number;
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProfilesDtoListResponseModel, any>({
      path: `/Profiles/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profiles
   * @name PostProfiles
   * @request POST:/Profiles/Add
   */
  postProfiles = (data: ProfilesDto, params: RequestParams = {}) =>
    this.request<ProfilesDtoModelResponse, any>({
      path: `/Profiles/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profiles
   * @name GetByIdList
   * @request GET:/Profiles/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<ProfilesDtoModelResponse, any>({
      path: `/Profiles/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profiles
   * @name DeleteDelete
   * @request DELETE:/Profiles/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<ProfilesDtoModelResponse, any>({
      path: `/Profiles/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profiles
   * @name UpdateCreate
   * @request POST:/Profiles/Update
   */
  updateCreate = (data: ProfilesDto, params: RequestParams = {}) =>
    this.request<ProfilesDtoModelResponse, any>({
      path: `/Profiles/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
